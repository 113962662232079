<template>
  <footer>
    <q-separator></q-separator>
    <div class="q-px-md q-py-xs flex">
      <q-space></q-space>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="$t('MBO.BACK')"
        class="q-ma-xs"
        @click="onBackClick"
      ></q-btn>
      <q-btn
        :class="{ 'full-width': $q.screen.lt.sm }"
        :label="$t('MBO.SAVE')"
        class="q-ma-xs"
        color="primary"
        @click="onSaveClick()"
      ></q-btn>
    </div>
  </footer>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { useI18n } from 'vue-i18n';
  import UsersStore from '@/components/users/users.store';
  import UserStore from '@/components/users/user/user.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props) {
      const { state, dispatch } = useStore();
      const router = useRouter();
      const $q = useQuasar();
      const { t } = useI18n();
      const user = computed(() => state[UsersStore.name][UserStore.name].user);
      return {
        user,
        onBackClick() {
          router.push({ name: 'users.search' });
        },
        async onSaveClick() {
          props.validations.user.$touch();
          if (props.validations.user.$error) {
            $q.notify(t('MBO.FILL_REQUIRED_FIELDS'));
          } else {
            await dispatch(`${UsersStore.name}/${UserStore.name}/saveUser`, user.value);
            $q.notify(t('MBO.USER_WAS_SAVED'));
            await router.push({ name: 'users.search' });
          }
        },
      };
    },
  });
</script>
