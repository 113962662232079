<template>
  <em-table
    v-model:pagination="searchOutput.pagination"
    :fetch="getUsers"
    :columns="SearchColumns"
    :rows="searchOutput.content"
    row-key="name"
  >
    <template #top>
      <div
        :class="{ 'no-wrap': $q.screen.gt.xs }"
        class="full-width q-col-gutter-sm"
      >
        <div class="row q-col-gutter-sm">
          <div class="col-12 col-md-3 col-sm-6">
            <q-input
              v-model="searchInput.firstname"
              :label="$t('MBO.NAME')"
              hide-bottom-space
              outlined
              dense
            ></q-input>
          </div>
          <div class="col-12 col-md-3 col-sm-6">
            <q-input
              v-model="searchInput.surname"
              :label="$t('MBO.SURNAME')"
              hide-bottom-space
              outlined
              dense
            ></q-input>
          </div>
          <div class="col-12 col-md-3 col-sm-6">
            <q-input
              v-model="searchInput.email"
              :label="$t('MBO.EMAIL')"
              hide-bottom-space
              outlined
              dense
            ></q-input>
          </div>
          <div class="col-12 col-md-3 col-sm-6">
            <q-input
              v-model="searchInput.company"
              :label="$t('MBO.COMPANY')"
              hide-bottom-space
              outlined
              dense
            ></q-input>
          </div>
        </div>
        <div class="row q-col-gutter-sm">
          <div
            :class="{ 'q-py-xs': $q.screen.lt.sm }"
            class="flex col-12"
          >
            <q-space></q-space>
            <q-btn
              :class="['q-ma-xs', { 'full-width': $q.screen.lt.sm }]"
              color="primary"
              @click="onSearchClick"
            >
              <span>{{ $t('MBO.SEARCH') }}</span>
            </q-btn>
          </div>
        </div>
      </div>
    </template>
    <template #actions="{ item }">
      <q-btn
        v-if="hasFeature(Feature.usersWrite)"
        dense
        flat
        round
        icon="create"
        @click="onEditClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.MODIFY') }}</span>
        </q-tooltip>
      </q-btn>
      <q-btn
        v-if="hasFeature(Feature.usersDelete)"
        dense
        flat
        round
        icon="delete"
        @click="onDeleteClick(item)"
      >
        <q-tooltip>
          <span>{{ $t('MBO.DELETE') }}</span>
        </q-tooltip>
      </q-btn>
    </template>
  </em-table>
</template>
<script>
  import { defineComponent, computed, inject } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  import { useQuasar } from 'quasar';
  import { useI18n } from 'vue-i18n';
  import { hasFeature } from '@/store/helpers/access.helper';
  import { Feature } from '@/common/constants/security';
  import { SearchColumns } from '@/components/users/search/search.constant';
  import UsersStore from '@/components/users/users.store';
  import SearchStore from '@/components/users/search/search.store';
  import UserStore from '@/components/users/user/user.store';

  export default defineComponent({
    setup() {
      const { state, dispatch, commit } = useStore();
      const router = useRouter();
      const $q = useQuasar();
      const { t } = useI18n();
      const searchInput = computed(() => state[UsersStore.name][SearchStore.name].searchInput);
      const searchOutput = computed(() => state[UsersStore.name][SearchStore.name].searchOutput);
      const $confirm = inject('$confirm');

      function getUsers(payload) {
        if (payload) {
          commit(`${UsersStore.name}/${SearchStore.name}/setSearchInput`, {
            ...searchInput.value,
            ...payload,
          });
        }
        dispatch(`${UsersStore.name}/${SearchStore.name}/getUsers`, searchInput.value.searchObj);
      }
      return {
        hasFeature,
        Feature,
        SearchColumns,
        searchInput,
        searchOutput,
        getUsers,
        onSearchClick() {
          getUsers({ page: null });
        },
        onEditClick(payload) {
          commit(`${UsersStore.name}/${UserStore.name}/setUser`, payload);
          router.push({ name: 'users.user', params: { id: payload.id } });
        },
        async onDeleteClick({ id }) {
          const result = await $confirm({
            content: t('MBO.ARE_YOU_SURE_DELETE_ITEM'),
          });
          if (result) {
            try {
              await dispatch(`${UsersStore.name}/${SearchStore.name}/deleteUserById`, id);
              await getUsers();
              $q.notify(t('MBO.USER_WAS_DELETED'));
            } catch (error) {
              $q.notify(t('MBO.SOMETHING_WENT_WRONG'));
              throw error;
            }
          }
        },
      };
    },
  });
</script>
