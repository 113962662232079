<template>
  <router-view></router-view>
</template>
<script>
  import { defineComponent, onUnmounted } from 'vue';
  import Store from '@/store';
  import UsersStore from '@/components/users/users.store';

  export default defineComponent({
    beforeRouteEnter(to, from, next) {
      Store.registerModule(UsersStore.name, UsersStore.module);
      next();
    },
    setup() {
      onUnmounted(() => {
        Store.unregisterModule(UsersStore.name);
      });
    },
  });
</script>
