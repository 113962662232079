<template>
  <header class="text-h6 q-pb-md text-center">
    <span v-if="user.id">{{ $t('MBO.EDIT_USER') }}</span>
    <span v-else>{{ $t('MBO.ADD_NEW_USER') }}</span>
  </header>
</template>
<script>
  import { defineComponent, computed } from 'vue';
  import { useStore } from 'vuex';
  import UsersStore from '@/components/users/users.store';
  import UserStore from '@/components/users/user/user.store';

  export default defineComponent({
    setup() {
      const { state } = useStore();
      const user = computed(() => state[UsersStore.name][UserStore.name].user);
      return {
        user,
      };
    },
  });
</script>
