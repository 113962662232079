<template>
  <div class="q-gutter-md">
    <div class="row q-col-gutter-sm">
      <div class="col-12 col-sm-4">
        <q-input
          v-model="user.firstname"
          :label="$t('MBO.NAME')"
          outlined
          dense
        ></q-input>
      </div>
      <div class="col-12 col-sm-4">
        <q-input
          v-model="user.surname"
          :label="$t('MBO.SURNAME')"
          outlined
          dense
        ></q-input>
      </div>
    </div>
    <div class="row q-col-gutter-sm">
      <div class="col-12 col-sm-4">
        <q-input
          v-model="user.email"
          :error="v$.user.email.$error"
          :label="$t('MBO.EMAIL')"
          hide-bottom-space
          outlined
          dense
        ></q-input>
      </div>
      <div class="col-12 col-sm-4">
        <q-input
          v-model="user.company"
          :error="v$.user.company.$error"
          :label="$t('MBO.COMPANY')"
          hide-bottom-space
          outlined
          dense
        ></q-input>
      </div>
    </div>
    <div class="row q-col-gutter-sm">
      <div class="col-12 col-sm-4">
        <em-input-date
          v-model="user.startSending"
          :label="$t('MBO.START_SENDING')"
          hide-bottom-space
          dense
          clearable
        ></em-input-date>
      </div>
      <div class="col-12 col-sm-4">
        <em-input-date
          v-model="user.endSending"
          :label="$t('MBO.END_SENDING')"
          hide-bottom-space
          dense
          clearable
        ></em-input-date>
      </div>
    </div>
    <div class="row q-col-gutter-sm">
      <div class="col-12 col-sm-4">
        <q-select
          v-model="user.categories"
          :options="Categories"
          :label="$t('MBO.CATEGORY')"
          multiple
          map-options
          emit-value
          hide-bottom-space
          outlined
          dense
          clearable
        ></q-select>
      </div>
      <div class="col-12 col-sm-4">
        <q-select
          v-model="user.riskLevelList"
          :options="RiskLevels"
          :label="$t('MBO.RISK_LEVELS')"
          hide-bottom-space
          outlined
          dense
          clearable
        ></q-select>
      </div>
    </div>
    <div class="row q-col-gutter-sm">
      <div class="col-12 col-sm-4">
        <em-country-select
          v-model="user.countryList"
          :label="$t('MBO.COUNTRIES')"
          hide-bottom-space
          multiple
        ></em-country-select>
      </div>
      <div class="col-12 col-sm-4">
        <em-region-select
          v-model="user.regionList"
          :label="$t('MBO.REGIONS')"
          hide-bottom-space
          multiple
        ></em-region-select>
      </div>
    </div>
  </div>
</template>
<script>
  import { computed, defineComponent, ref } from 'vue';
  import { useStore } from 'vuex';
  import { Categories } from '@/common/constants/categories';
  import { RiskLevels } from '@/common/constants/risk-levels';
  import UsersStore from '@/components/users/users.store';
  import UserStore from '@/components/users/user/user.store';

  export default defineComponent({
    props: {
      validations: {
        type: Object,
      },
    },
    setup(props) {
      const { state } = useStore();
      const user = computed(() => state[UsersStore.name][UserStore.name].user);
      return {
        v$: ref(props.validations),
        Categories,
        RiskLevels,
        user,
      };
    },
  });
</script>
