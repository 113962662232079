<template>
  <div :class="$style.component">
    <div class="q-pa-md overflow-auto">
      <header-component></header-component>
      <form-component :validations="v$"></form-component>
    </div>
    <q-space></q-space>
    <footer-component :validations="v$"></footer-component>
  </div>
</template>
<script>
  import { computed, defineComponent } from 'vue';
  import { useStore } from 'vuex';
  import useVuelidate from '@vuelidate/core';
  import { required } from '@vuelidate/validators';
  import HeaderComponent from '@/components/users/user/components/header.component.vue';
  import FormComponent from '@/components/users/user/components/form.component.vue';
  import FooterComponent from '@/components/users/user/components/footer.component.vue';
  import Store from '@/store';
  import UsersStore from '@/components/users/users.store';
  import UserStore from '@/components/users/user/user.store';

  export default defineComponent({
    async beforeRouteEnter(to, from, next) {
      try {
        const userId = to.params.id;
        if (userId) {
          await Store.dispatch(`${UsersStore.name}/${UserStore.name}/getUserById`, userId);
        } else {
          Store.commit(`${UsersStore.name}/${UserStore.name}/setUser`);
        }
        next();
      } catch (error) {
        next({ name: 'users.search' });
        throw error;
      }
    },
    setup() {
      const { state } = useStore();
      const v$ = useVuelidate();
      const user = computed(() => state[UsersStore.name][UserStore.name].user);
      return {
        v$,
        user,
      };
    },
    validations() {
      return {
        user: {
          email: { required },
          company: { required },
        },
      };
    },
    components: {
      HeaderComponent,
      FormComponent,
      FooterComponent,
    },
  });
</script>
<style lang="scss" module>
  @import '~@/styles/common/item-component';

  .component {
    @include item-component();
  }
</style>
