<template>
  <div :class="$style.component">
    <header-component></header-component>
    <table-component class="q-pa-md"></table-component>
  </div>
</template>
<script>
  import { defineComponent, inject } from 'vue';
  import { startAutoRefreshing } from '@/common/chunks/auto-refresh';
  import HeaderComponent from '@/components/users/search/components/header.component.vue';
  import TableComponent from '@/components/users/search/components/table.component.vue';
  import Store from '@/store';
  import UsersStore from '@/components/users/users.store';
  import SearchStore from '@/components/users/search/search.store';

  export default defineComponent({
    async beforeRouteEnter(to, from, next) {
      try {
        const { searchObj } = Store.state[UsersStore.name][SearchStore.name].searchInput;
        await Store.dispatch(`${UsersStore.name}/${SearchStore.name}/getUsers`, searchObj);
        next();
      } catch (error) {
        next(false);
        throw error;
      }
    },
    setup() {
      startAutoRefreshing({
        dispatchName: `${UsersStore.name}/${SearchStore.name}/getUsers`,
        searchState: Store.state[UsersStore.name][SearchStore.name],
        autoRefresh: inject('$autoRefresh'),
      });
    },
    components: {
      HeaderComponent,
      TableComponent,
    },
  });
</script>
<style lang="scss" module>
  .component {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    flex: 1 0 100%;
    overflow: auto;
  }
</style>
